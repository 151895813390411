import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Brandon Kaplan leads `}<a parentName="p" {...{
        "href": "https://www.skilledcreative.com/"
      }}>{`Skilled Creative`}</a>{`, where he helps his partners explore emerging technologies like voice. We’re thrilled to welcome him to the SuperBot stage in March, and wanted to get to know him better before that. We sat down with Brandon to ask about his thoughts on the voice space.`}</p>
    <h3>{`Why did you start Skilled Creative?`}</h3>
    <p>{`I’ve always had a passion for emerging technology, and wanted to combine my passions of tech—big idea solutions— and partnerships. I went to an Amazon Alexa workshop in early 2016 that melted my brain. From that point forward I knew I wanted to include VOICE in my business. I was launching Skilled Creative in 2017 as an emerging tech creative agency, building VOICE, Computer Vision, Augmented Reality, and other emerging technologies for brands, but in 2018 we became a full service VOICE agency.`}</p>
    <h3>{`What excites you about the voice assistant space?`}</h3>
    <p>{`The creative blank space, and the ability to truly drive action and transactions. All of the tools are there, in a nascent state, it’s up to us as brands and agencies to define what the killer experiences will be on VOICE, and how it all connects back to a strategic campaign or business.`}</p>
    <h3>{`What types of clients are typically looking for voice skills— do you see any industries or use cases that are more common?`}</h3>
    <p>{`We focus heavily on Media, Entertainment, Gaming, and CPG. We’ve also worked in healthcare, education, and finance, but we’ve seen that consumer brands in entertainment and CPG are most active right now, and the platforms are very excited about interactive entertainment.`}</p>
    <h3>{`What tips would you give a brand interested in launching a voice skill?`}</h3>
    <p>{`This is a very layered question, that could be answered in hundreds of pages, but a blind spot many teams don’t factor for is correctly staffing a project. If you are not staffed correctly, then the timeline, product, and creativity will suffer. If you have the ability to really map out your staffing the product will have an opportunity to really shine. In general though, make sure you think through the use case, think through the strategy behind the experience, and focus on good quality details.`}</p>
    <h3>{`What are you looking forward to at SuperBot?`}</h3>
    <p>{`Community building, sharing insights and war stories with colleagues in the industry, and hopefully picking up a few gems of ideas that we can take back to our team.`}</p>
    <hr></hr>
    <p><em parentName="p">{`Skilled Creative is a group of Skilled Creatives and Technologists, obsessed with emerging technologies. They help brands innovate their creative and business strategies by leveraging bleeding edge technologies`}</em>{`. `}<em parentName="p">{`To learn more about Brandon and Skilled Creative`}</em>{`, `}<em parentName="p">{`register for SuperBot, where Brandon will be speaking.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      